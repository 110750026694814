<template>
  <v-row>
    <v-col
      cols="12"
      md="6">
      <v-row align="start">
        <v-col cols="6">
          <span class="font-weight-bold">
            ผู้ใหญ่
          </span>
          <span style="font-size: 12px;">
            (รวมตั๋วเครื่องบิน)
          </span>
        </v-col>
        <v-col
          cols="6"
          class="d-flex flex-row justify-space-between align-center">
          <button
            type="button"
            class="adjust-btn minus-btn"
            @click="adjustPassenger('adult', true, false)">
            <v-icon color="#E0E0E0">
              mdi-minus
            </v-icon>
          </button>
          <span>
            {{ passengers.adultWithTicket }}
          </span>
          <button
            type="button"
            class="adjust-btn add-btn"
            @click="adjustPassenger('adult', true, true)">
            <v-icon color="#1288DE">
              mdi-plus
            </v-icon>
          </button>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6">
      <v-row align="start">
        <v-col cols="6">
          <span class="font-weight-bold">
            เด็ก
          </span>
          <span style="font-size: 12px;">
            (รวมตั๋วเครื่องบิน)
          </span>
          <br>
          <span style="font-size: 12px; color: #828282;">
            อายุ 0 - 12 ปี
          </span>
        </v-col>
        <v-col
          cols="6"
          class="d-flex flex-row justify-space-between align-center">
          <button
            type="button"
            class="adjust-btn minus-btn"
            @click="adjustPassenger('kid', true, false)">
            <v-icon color="#E0E0E0">
              mdi-minus
            </v-icon>
          </button>
          <span>
            {{ passengers.kidWithTicket }}
          </span>
          <button
            type="button"
            class="adjust-btn add-btn"
            @click="adjustPassenger('kid', true, true)">
            <v-icon color="#1288DE">
              mdi-plus
            </v-icon>
          </button>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6">
      <v-row align="start">
        <v-col cols="6">
          <span class="font-weight-bold">
            ผู้ใหญ่
          </span>
          <span style="font-size: 12px;">
            (ไม่รวมตั๋วเครื่องบิน)
          </span>
        </v-col>
        <v-col
          cols="6"
          class="d-flex flex-row justify-space-between align-center">
          <button
            type="button"
            class="adjust-btn minus-btn"
            @click="adjustPassenger('adult', false, false)">
            <v-icon color="#E0E0E0">
              mdi-minus
            </v-icon>
          </button>
          <span>
            {{ passengers.adultWithOutTicket }}
          </span>
          <button
            type="button"
            class="adjust-btn add-btn"
            @click="adjustPassenger('adult', false, true)">
            <v-icon color="#1288DE">
              mdi-plus
            </v-icon>
          </button>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6">
      <v-row align="start">
        <v-col cols="6">
          <span class="font-weight-bold">
            เด็ก
          </span>
          <span style="font-size: 12px;">
            (ไม่รวมตั๋วเครื่องบิน)
          </span>
          <br>
          <span style="font-size: 12px; color: #828282;">
            อายุ 0 - 12 ปี
          </span>
        </v-col>
        <v-col
          cols="6"
          class="d-flex flex-row justify-space-between align-center">
          <button
            type="button"
            class="adjust-btn minus-btn"
            @click="adjustPassenger('kid', false, false)">
            <v-icon color="#E0E0E0">
              mdi-minus
            </v-icon>
          </button>
          <span>
            {{ passengers.kidWithOutTicket }}
          </span>
          <button
            type="button"
            class="adjust-btn add-btn"
            @click="adjustPassenger('kid', false, true)">
            <v-icon color="#1288DE">
              mdi-plus
            </v-icon>
          </button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    passengers () {
      return this.value
    }
  },
  methods: {
    adjustPassenger (age, isNeedTicket, isAdd) {
      if (this.disabled) {
        return
      }

      this.$emit('adjust', { age, isNeedTicket, isAdd })
    }
  }
}
</script>

<style scoped>
.adjust-btn {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  padding-bottom: 2px;
}
.adjust-btn:disabled {
  border: 2px solid #E0E0E0;
  cursor: not-allowed;
}
.add-btn {
  border: 2px solid #1288DE;
}
.minus-btn {
  border: 2px solid #E0E0E0;
}
</style>

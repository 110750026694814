<template>
  <div class="w-full">
    <v-row class="pa-3">
      <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="12">
        <div
          class="d-flex flex-row align-center"
          style="gap: 1rem;">
          <a
            v-if="item.mediaUrl"
            :href="item.mediaUrl"
            target="_blank">
            {{ item.name }}
          </a>
          <span v-else>
            {{ item.name }}
          </span>
          <v-btn
            icon
            color="error"
            :disabled="disabled"
            @click="removeItem(i)">
            <v-icon>
              mdi-delete-empty
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div class="upload-container">
      <div
        class="uploader"
        @dragover.prevent
        @drop.prevent="onDropFile($event)"
        @click="chooseFile()" />
      <input
        :id="name"
        type="file"
        :multiple="multiple"
        style="display:none"
        :disabled="disabled"
        @change="uploadFromInput()" />
      <v-btn
        color="primary"
        outlined
        :disabled="(maxFile && (items.length === maxFile)) || disabled">
        <v-icon class="mr-1">
          mdi-tray-arrow-up
        </v-icon>
        <span>
          {{ label || 'อัพโหลดไฟล์' }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: 'fileBrowser'
    },
    label: {
      type: String,
      default: ''
    },
    maxFile: {
      type: Number,
      default: null
    },
    maxSize: {
      type: Number,
      default: 4
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    chooseFile () {
      if (this.disabled) {
        return
      }

      if (this.maxFile && this.items.length < this.maxFile) {
        document.getElementById(this.name).value = ''
        document.getElementById(this.name).click()
      }
    },
    uploadFromInput () {
      if (this.disabled) {
        return
      }

      const { files } = document.getElementById(this.name)
      this.uploadFiles(files)
    },
    onDropFile (e) {
      if (this.disabled) {
        return
      }

      const { files } = e.dataTransfer

      if (this.maxFile && (this.items.length === this.maxFile)) {
        return
      }

      if (this.maxFile && (files.length + this.items.length) > this.maxFile) {
        const diff = this.maxFile - this.items.length
        const sliced = [...files].slice(0, diff)

        this.uploadFiles(sliced)
      } else {
        this.uploadFiles(files)
      }
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = (f) => {
          resolve({
            file,
            name: file.name,
            preview: f.target.result,
            mediaUrl: null
          })
        }

        reader.onerror = () => {
          reject(reader)
        }

        reader.readAsDataURL(file)
      })
    },
    async uploadFiles (files) {
      try {
        const maxSize = this.maxSize * 1024 * 1024
        const checkOverSize = [...files].some((file) => file.size > maxSize)

        if (checkOverSize) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: `ขนาดของรูปภาพจะต้องไม่เกิน ${this.maxSize} MB`,
            type: 'error',
            timeout: 2000
          })

          return
        }

        const previewPromises = []
        const len = this.items.length

        let previewIndex = 0
        for (const file of files) {
          if (!this.maxFile || (previewIndex + len) < this.maxFile) {
            previewPromises.push(this.getBase64(file))
          }

          previewIndex++
        }

        const previewResults = await Promise.all(previewPromises)
        this.items.push(...previewResults)

        if (this.autoUpload) {
          const resultPromises = []

          // let resultIndex = 0
          // for (const file of files) {
          //   if (!this.maxFile || (resultIndex + len) < this.maxFile) {
          //     resultPromises.push(UploaderService.uploadFile(file))
          //   }

          //   resultIndex++
          // }

          // for (const file of files) {
          //   resultPromises.push(UploaderService.uploadFile(file))
          // }

          const results = await Promise.all(resultPromises)
          for (const [index, result] of results.entries()) {
            this.items[len + index].mediaUrl = result.data.public_url
          }
        }
      } catch (error) {
        console.error('uploadFiles', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      }
    },
    removeItem (index) {
      this.items.splice(index, 1)
    },
    isMaxFile () {
      return this.maxFile && (this.items.length === this.maxFile)
    },
    checkImageUrl (image) {
      return (image?.mediaUrl || !this.autoUpload) ? 'opacity-full' : 'opacity-half'
    }
  }
}
</script>

<style scoped>
.relative {
  position: relative;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.upload-container {
  position: relative;
  z-index: 1;
  width: fit-content;
}
.opacity-half {
  opacity: 0.5;
}
.opacity-full {
  opacity: 1;
}
.uploader {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
</style>
